import clsx from 'clsx'
import { useSearchParam } from 'react-use'

import { PaginationLink } from './PaginationLink'

type Props = {
	total: number
	limit: number
	current?: number
	handlePageChange?: (page: number) => void
	nextText?: string
	prevText?: string
	className?: string
	scrollToTop?: boolean
}

export function Pagination({
	className,
	total,
	current,
	nextText,
	prevText,
	handlePageChange,
	limit = 10,
	scrollToTop = false
}: Props) {
	const page = useSearchParam('page')

	if (total <= limit) return null

	const totalPages = Math.ceil(total / limit)
	const currentPage = current ?? Number(page ?? 1)
	const breakpoint = totalPages > 10 ? 6 : Math.floor(totalPages / 2)
	const isStart = currentPage < breakpoint
	const isEnd = currentPage + breakpoint > totalPages
	const isMiddle = !isStart && !isEnd
	const pages = Array.from({ length: totalPages }, (_, i) => i + 1)

	if (totalPages <= 9) {
		return (
			<div
				data-testid="pagination"
				className={clsx(className, 'px-4 grid grid-flow-col gap-4 w-fit mx-auto')}
			>
				{currentPage > 1 && (
					<PaginationLink
						handlePageChange={handlePageChange}
						page={prevText || 'Previous'}
						type="prev"
						current={currentPage}
						scrollToTop={scrollToTop}
					/>
				)}
				<div className="grid grid-flow-col gap-4">
					{pages.map((page) => (
						<PaginationLink
							handlePageChange={handlePageChange}
							key={page}
							page={page}
							current={currentPage}
							scrollToTop={scrollToTop}
						/>
					))}
				</div>
				{currentPage < totalPages && (
					<PaginationLink
						handlePageChange={handlePageChange}
						page={nextText || 'Next'}
						type="next"
						current={currentPage}
						scrollToTop={scrollToTop}
					/>
				)}
			</div>
		)
	}

	return (
		<div
			data-testid="pagination"
			className={clsx(className, 'px-4 grid grid-flow-col gap-4 w-fit mx-auto')}
		>
			{currentPage > 1 && (
				<PaginationLink
					handlePageChange={handlePageChange}
					page={prevText || 'Previous'}
					type="prev"
					current={currentPage}
					scrollToTop={scrollToTop}
				/>
			)}
			{isStart && (
				<div className="grid grid-flow-col gap-4">
					{pages.slice(0, 6).map((page) => (
						<PaginationLink
							handlePageChange={handlePageChange}
							key={page}
							page={page}
							current={currentPage}
							scrollToTop={scrollToTop}
						/>
					))}
					<p className="text-bc-blue font-normal text-sm">..</p>
					{pages.slice(-2).map((page) => (
						<PaginationLink
							handlePageChange={handlePageChange}
							key={page}
							page={page}
							current={currentPage}
							scrollToTop={scrollToTop}
						/>
					))}
				</div>
			)}
			{isEnd && (
				<div className="grid grid-flow-col gap-4">
					{pages.slice(0, 2).map((page) => (
						<PaginationLink
							handlePageChange={handlePageChange}
							key={page}
							page={page}
							current={currentPage}
							scrollToTop={scrollToTop}
						/>
					))}
					<p className="text-bc-blue font-normal text-sm">..</p>
					{pages.slice(-5).map((page) => (
						<PaginationLink
							handlePageChange={handlePageChange}
							key={page}
							page={page}
							current={currentPage}
							scrollToTop={scrollToTop}
						/>
					))}
				</div>
			)}
			{isMiddle && (
				<div className="grid grid-flow-col gap-4">
					{pages.slice(0, 2).map((page) => (
						<PaginationLink
							handlePageChange={handlePageChange}
							key={page}
							page={page}
							current={currentPage}
							scrollToTop={scrollToTop}
						/>
					))}
					<p className="text-bc-blue font-normal text-sm">..</p>

					{pages.slice(currentPage - 3, currentPage + 2).map((page) => (
						<PaginationLink
							handlePageChange={handlePageChange}
							key={page}
							page={page}
							current={currentPage}
							scrollToTop={scrollToTop}
						/>
					))}

					<p className="text-bc-blue font-normal text-sm">..</p>
					{pages.slice(-2).map((page) => (
						<PaginationLink
							handlePageChange={handlePageChange}
							key={page}
							page={page}
							current={currentPage}
							scrollToTop={scrollToTop}
						/>
					))}
				</div>
			)}
			{currentPage < totalPages && (
				<PaginationLink
					handlePageChange={handlePageChange}
					page={nextText || 'Next'}
					type="next"
					current={currentPage}
					scrollToTop={scrollToTop}
				/>
			)}
		</div>
	)
}
